import React, {Component} from 'react';
import { Container, Row, Col, CardColumns, Card, Button } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {connect} from 'react-redux';
import { Line } from 'react-chartjs-2';
import {Helmet} from 'react-helmet';
import Loading from 'components/Loading';

import {paramsLoad} from 'actions/paramsActions';
import CSVToArray from 'modules/csvtoarray';

import './explore.css';

const palette = ["#5b9bd5", "#ed7d31", "#a5a5a5", "#ffc000", "#4472c4", "#70ad47", "#9e480e", "#997300", "#43682b", "#636363", "#264478"];
const compare = false;
const snap = navigator.userAgent === 'ReactSnap';


class Explore extends Component {

    constructor(props) {
        super(props);
        if (!props.portData) props.dispatch(paramsLoad({type: 'samplePortData'}));
        if (!props.portDesc) props.dispatch(paramsLoad({type: 'samplePortDesc'}));
        this.state = {sel: null};
    }

    componentDidMount() {
        if (this.props.portData && this.props.portDesc) {
            this.setState({update: true});
        }        
    }

    componentDidUpdate() {

        let ready = this.props.portData && this.props.portDesc, newState = {};

        if (ready && !this.state.portfolios) {
            newState.portfolios = this.props.portDesc.map((e, i) => ({
                ...e, 
                id: e.data + i,
                portId: e.data,
                metrics: this.props.portData[e.data]?.metrics,
                data: this.props.portData[e.data]?.values
            }))
                .filter(e => e.data && e.metrics)
                .map(e => {
                    const data = CSVToArray(e.data);
                    const datasets = [];
                    for (let i=1; i < data[0].length; i++) {
                        const startVal = data[1][i];
                        datasets.push({
                            label: data[0][i],
                            data: data.slice(1)
                                .map(e => ({t: new Date(e[0]), y: e[i]/startVal}))
                                .filter(e => e.t >= new Date('2000-01-01')),
                            backgroundColor: 'rgba(0,0,0,0)',
                            borderColor: palette[i-1],
                            borderWidth: 2
                        });
                    }
                    e.data = {datasets};
                    return e;
                });
            this.setState(newState);
        }
    }


    render() {

        return (
            <div onClick={() => this.setState({sel: null})} className="explore">
                <Helmet>
                    <title>Explore sample portfolios | FuturesBacktest</title>
                    <meta name="description" content="Our sample portfolios gallery showcase some of the strategies we offer, from simple 60/40 to advanced, diversified strategies." />
                </Helmet>
                <Container>
                    <Row>
                        <Col>
                            <h1>Explore sample portfolios</h1>
                            {(!this.state.portfolios || snap) && <Loading />}
                            {this.state.portfolios && !snap && <React.Fragment>
                            <p>You can find here a few futures contracts portfolios ready to use in your backtests. 
                                Click to see the details and start backtesting these strategies, which you can use as starting points.</p>
                            <CardColumns>
                            {this.state.portfolios.map((e) => <Card 
                            className="sample-port"
                            key={e.id}
                            style={{cursor: 'pointer'}}
                            onClick={(ev) => {
                                if (this.state.sel !== e.id) {
                                    ev.stopPropagation();
                                    this.setState({sel: e.id});
                                }
                            }} >
                                <Card.Body>
                                    <Card.Title>{e.name}</Card.Title>
                                    {this.state.sel !== e.id && <Card.Text>                                        
                                        <Line
                                        data={e.data}
                                        options={{
                                            legend: {display: false},
                                            scales: {
                                                xAxes: [{
                                                    type: 'time',
                                                    time: {
                                                        unit: 'year',
                                                        stepSize: 5
                                                    }
                                                }],
                                                yAxes: [{
                                                    type: 'logarithmic',
                                                    ticks: {
                                                        autoSkipPadding: 20,
                                                        callback: compare ? (v) => (Math.round(v*100) + '%') : (v) => (v)
                                                    }
                                                }]
                                            },
                                            elements: {
                                                point:{radius: 0},
                                                line: {tension: 0}
                                            },
                                            tooltips: {enabled: false},
                                            animation: {duration: 0}
                                        }} />
                                        {e.description}
                                        </Card.Text>}
                                        {this.state.sel === e.id && <React.Fragment>
                                            <table className="mb-2"><tbody>
                                                {e.metrics.map((m, i) => <tr key={m[0]}>
                                                    <td>{m[0]}</td>
                                                    <td>{m[1]}</td>
                                                </tr>)}
                                            </tbody></table>
                                            <Card.Text>
                                                Annualized statistics calculated on monthly returns, since January 2000.
                                            </Card.Text>
                                            <div className="text-center mt-3">
                                                <LinkContainer to={"/backtest/" + e.portId + "/"}>
                                                <Button variant="outline-primary">View in Backtest</Button>
                                            </LinkContainer></div>
                                        </React.Fragment>}
                                </Card.Body>
                                
                            </Card>)}    
                            </CardColumns></React.Fragment>}
                        </Col>
                    </Row>
                </Container>

            </div>);
    }

}

function mapStateToProps(state) {
    return {
        portDesc: state.params.samplePortDesc,
        portData: state.params.samplePortData
    };
}

export default connect(mapStateToProps)(Explore);